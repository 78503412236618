import React from 'react'

function LogoPage() {
  return (
    <section>
      <p>Logo and Artwork Template Page</p>
    </section>
    
    
  )
}

export default LogoPage